@import "../../../scss/mixin.scss";

.nftDetHd {
    font-size: 30px;
    color: #fff;
    font-weight: 600;
}
.nftDetOuterBx{
    max-width: 75%;
    @include respond-to(media-lg) {
        max-width: 100%;
    }
}
.nftDetailBx{
    color: #fff;
    h2{
        font-size: 38px;
        padding-top: 15px;
    }
    small{
        font-size: 16px;
        display: block;
        color: #858584;
        padding-bottom: 10px;
    }
    strong{
        font-size: 16px;
        padding-bottom: 10px;
        display: block;
    }
    span{
        display: block;
        font-size: 16px;
        padding-bottom: 20px;
    }
    p{
        font-size: 16px;
        line-height: 30px;
    }
}
.bidOuter {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 20px;
    padding-top: 15px;
    .bidInn {
        width: 48%;
        color: #fff;
        padding: 20px;
        background: rgba(49, 47, 49, 0.6);
        border: 1px solid #545454;
        border-radius: 10px;
        span{
            display: block;
            font-size: 15px;
        }
        strong{
            display: block;
            font-size: 26px;
            line-height: 34px;
            font-weight: 600;
            padding: 5px 0;
        }
    }
}
.timerBg{
    background: rgba(49, 47, 49, 0.6);
    padding: 30px;
    border: 1px solid #545454;
    border-radius: 16px;
    margin-bottom: 20px;
    @include respond-to(media-md) {
        padding: 15px;
    }
    strong{
        color: #fff;
        display: block;
        font-size: 16px;
        padding-bottom: 10px;
        font-weight: 500;
    }
}
.timeCountdown{
    color: #fff;
    background: rgba(49, 47, 49, 0.6);
    border: #545454 solid 1px;
    border-radius: 16px;
    padding: 20px;
    @include respond-to(media-xs) {
        padding: 15px 10px;
    }
    h2{
        display: flex;
        justify-content: space-between;
        font-size: 38px;
        @include respond-to(media-xs) {
            font-size: 26px;
        }
        span{
            width: 80px;
        }
    }
    h5{
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        @include respond-to(media-xs) {
            font-size: 13px;
        } 
        span{
            width: 80px;
        }
    }
}
.priceHistOuter{
    margin-bottom: 20px;
    color: #fff;
    background: rgba(49, 47, 49, 0.6);
    border: #545454 solid 1px;
    border-radius: 16px;
    .pricehistHd{
        border-bottom: #545454 solid 1px;
        padding: 10px 15px;
        font-size: 20px;
        font-weight: 700;
    }
}
.priceTable{
    .table{
        color: #fff;
        th{
            border: 0;
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
        }
        td{
            border-bottom: 0;
            border-top: #545454 solid 1px;
            font-size: 17px;
            font-weight: 600;
        }
    }
}
.priceChart {
    padding: 20px;
    img{
        width: 100%;
    }
}