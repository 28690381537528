@import "../../../scss/mixin.scss";

.tokensOuter{
    padding: 70px 145px 100px 145px;
    background: rgba(18, 18, 18, 0.6);
    border-radius: 16px;
    width: 100%;
    margin-bottom: 30px;
    @include respond-to(media-lg) {
        padding: 70px 50px 60px 50px; 
    }
    .gspFldBx{
        margin-bottom: 70px;
        position: relative;
        .selGsp{
            position: absolute;
            left: 20px;
            top:50%;
            transform: translate(0, -50%);
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            img{
                width: 22px;
                margin-right: 5px;
            }
        }
        svg{
            position: absolute;
            right: 20px;
            top: 50%;
            font-size: 30px;
            font-weight: 600;
            transform: translate(0, -50%);
            color: #666;
        }
        .form-select{
            height: 79px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            border: rgba(255, 255, 255, 0.1) solid 1px;
            font-size: 24px;
            text-align: right;
            color: #000;
            padding: 0 70px 0 90px;
            box-shadow: none;
        }
    }
    .gspadd{
        padding-bottom: 40px;
        .form-control{
            height: 79px;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            color: #fff;
            font-size: 20px;
            border-radius: 10px;
            box-shadow: none;
        }
    }
}
.withdrawOuter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .withdraw {
        width: 87%;
        @include respond-to(media-lg) {
           width: 83%;
        }
        .mb-3{
            margin-bottom: 0 !important;
        }
        .form-control {
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            height: 79px;
            font-size: 20px;
            box-shadow: none;
            color: #fff;
        }
    }    
    .maxBx {
        position: relative;
        width: 10%;
        @include respond-to(media-lg) {
            width: 15%;
         }
        input{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 99;
            opacity: 0;
        }
        span{
            display: block;
            background: rgba(255, 255, 255, 0.05);
            border-radius: 10px;
            height: 79px;
            position: relative;
            text-align: center;
            font-weight: 800;
            font-size: 23px;
            line-height: 28px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.5);
            line-height: 79px;
        }
        
    }
}
.freetext {
    text-align: center;
    padding: 40px 0 60px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.5);
    span{
        display: inline-block;
        margin: 0 40px;
    }
}
.transfBtn{
    width: 315px;
    height: 67px;
    font-weight: 600 !important;
    font-size: 20px !important;
    text-align: center !important;
    color: #FFFFFF !important;
    background: linear-gradient(270deg, #7C0FF6 5.64%, #0A8FFC 87.41%);
    border-radius: 10px !important;
    margin: 0 auto;
    display: block !important;
    border: 0 !important;
    &:hover{
        background: linear-gradient(270deg, #0A8FFC 5.64%, #7C0FF6 87.41%);
    }
}