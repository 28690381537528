@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  list-style: none;
}
.mainCon {
  max-width: 1024px;
  width: 100%;
  margin: auto;
  padding: 15px;
}
.logo {
  text-align: center;
}
.formBox {
  background: hsla(0, 0%, 7%, 0.7);
  border-radius: 16px;
  padding: 25px;
  font-family: "Roboto", sans-serif;
}
.formBox ul {
  margin: -10px;
  padding: 0;
}
.formBox ul li {
  padding: 10px;
  display: flex;
  align-items: center;
}
.field {
  display: block;
  color: #fff;
  font-size: 18px;
  width: 270px;
  min-width: 270px;
}
.formInput {
  width: 100%;
  background: hsla(0, 0%, 100%, 0.15) !important;
  border-radius: 10px;
  height: 50px;
  padding: 0 20px;
  border: none;
  resize: none;
  font-family: "Roboto", sans-serif;
  outline: none;
  font-size: 16px;
  color: #fff;
}
.formBtn {
  background: linear-gradient(270deg, #7c0ff6 5.64%, #0a8ffc 87.41%);
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  height: 55px;
  width: 250px;
  color: #fff;
  margin: auto;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
.tac {
  text-align: center;
}
.tc {
  text-align: right;
  color: #ccc;
  font-size: 14px;
  padding-bottom: 5px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}
.header .heading {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.centered {
  text-align: center;
  font-size: 16px; /* Adjust the font size as needed */
}

.blue-text {
  color: blue;
  font-size: 40px;
}

@media (max-width: 767px) {
  .formBox {
    padding: 15px;
  }
  .formBox ul li {display: block;}
  .field {
    font-size: 15px;
    width: 100%;
    min-width: 100%;
    padding-bottom: 5px;
  }
}

.gdPop {height: 100vh;
  display: flex;
  align-items: center;
  margin: auto !important;
  padding: 15px;}
  .gdpCont {text-align: center;}