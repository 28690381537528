@import "../../../scss/mixin.scss";

.aftloginheader{
    padding-bottom: 20px;
}
.loginUser{
    margin-left: 0 !important;
    i{
        background: linear-gradient(90deg, #69C6E1 0%, #69C6E1 94.27%);
        width: 24px;
        height: 24px;
        display: inline-block;
        display: inline-block;
        border-radius: 50%;
        vertical-align: top;
        margin-left: 8px;
    }
    svg{
        margin-left: 5px;
        font-size: 20px;
    }
}
.headRight.adminname {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    span {
        display: block;
        text-align: right;
        padding-bottom: 10px;
    }
    button {
        border: #5253d6 solid 1px;
        background: #5253d6 !important;
        width: 230px;
    }
}
.samplebtn{
    padding: 20px 20px 0 20px;
    button{
        margin-bottom: 15px;
        display: block;
        width: 100%;
    }
}