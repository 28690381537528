@import "../../../scss/mixin.scss";

.howitOuter{
    padding: 0px 100px 50px 30px;
    .howHd {
        font-weight: 400;
        font-size: 65px;
        line-height: 83px;
        color: #fff;
        text-transform: uppercase;
    }
    .accordialList{
        padding-bottom: 50px;
        .szh-accordion__item {
            border-bottom: #fff solid 1px;
            padding: 15px 0;
            h3.szh-accordion__item-heading{
                button{
                    background: transparent;
                    color: #fff;
                    border: 0;
                    font-family: 'Montserrat', sans-serif !important;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 34px;
                    width: 100%;
                    text-align: left;
                    position: relative;
                    padding: 5px 0;
                    &:after{
                        background: url(../../../assets/images/down-arrow.png) right center no-repeat;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        content: '';
                        width: 23px;
                        height: 10px;
                    }
                }
            }
        }
        .szh-accordion__item--expanded{
            button{
                &:after{
                    content: '';
                    transform: rotate(180deg) !important;
                    transition: all 0.5s ease-in-out;
                }
            }
        }
        .szh-accordion__item-content {
            font-size: 18px;
            color: #e5e5e5;
            font-weight: 300;
        }
    }
    .featList{
        padding-top: 20px;
        ul{
            li{
                padding: 0 0 0 70px;
                position: relative;
                color: #fff;
                margin-bottom: 40px;
                h2{
                    font-size: 60px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                }
                p{
                    font-weight: 400;
                    font-size: 40px;
                }
            }
        }
    }
}