
@import "../../../scss/mixin.scss";
.loginOuterBg{
    background: url(.././../../assets/images/loginBg.png) left top no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
}
.LoginOuter{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .loginLeft{
        padding-top: 80px;
        h2{
            font-weight: 400;
            font-size: 65px;
            line-height: 83px;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }
}
.LoginBg{
    background: url(.././../../assets/images/loginshadow.png) left top no-repeat;
    background-size: 100% 100%;
    border-radius: 40px;
    padding: 40px 65px 40px 100px;
    margin-right: -50px;
   .loginBgInn{
    background: rgba(28, 28, 28, 0.7);
    border-radius: 16px;
    width: 620px;
    padding: 25px;
   }
    .loginLogo{
        text-align: center;
        a{
            @include respond-to(media-xs) {
                max-width: 140px;
                display: inline-block;
            }
        }
    }
    .loginHd {
        font-size: 36px;
        color: #fff;
        margin-bottom: 20px;
        font-weight: 600;
        @include respond-to(media-xs) {
            padding: 20px 0 0 0;
            font-size: 24px;
        }
    }
    .frmbx{
        margin-bottom: 20px;
        @include respond-to(media-xs) {
            margin-bottom: 15px;
        }
        .mb-3 {
            margin-bottom: 0rem!important;
            label{
                font-size: 16px;
                color: #fff;
                padding-bottom: 5px;
            }
            .form-control {
                background: rgba(255, 255, 255, 0.15);
                border-radius: 10px;
                height: 50px;
                color: #fff;
                box-shadow: none;
                border: 0;
                font-size: 18px;
                @include respond-to(media-xs) {
                    height: 50px;
                    font-size: 16px;
                }
            }
        }
    }
    .rembBx{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;
        padding-bottom: 20px;
        @include respond-to(media-xs) {
            margin-top: -10px;
        }
        .rembLeft{
            font-size: 15px;
            color: #fff;
            @include respond-to(media-xs) {
                font-size: 13px;
            }
            label{
                line-height: 23px;
                input{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    vertical-align: top;
                    display: inline-block;
                    position: relative;
                    
                    top: -3px;
                    @include respond-to(media-xs) {
                        width: 15px;
                        height: 15px;
                        margin-right: 4px; 
                    }
                }
            }
        }
        .forgPass{
            a{
                font-size: 14px;
                color: #fff;
                @include respond-to(media-xs) {
                    font-size: 12px;
                }
                &:hover{
                    color: #0099ff;
                    text-decoration: underline !important;
                }
            }
        }
    }
    .loginbtn{
        padding-top: 20px;
        @include respond-to(media-xs) {
            padding-top: 0;
        }
        button{
            width: 183px;
            height: 53px;
            background: transparent;
            border: #fff solid 2px;
            font-size: 16px;
            color: #fff;
            border-radius: 10px;
            margin: 5px;
            @include respond-to(media-xs) {
                height: 45px;
                font-size: 17px;
            }
            &:hover{
                background: #4b79f3;
                border: #4b79f3 solid 2px;
            }
        }
    }
    .dontacc{
        font-size: 14px;
        color: #fff;
        padding-top: 20px;
        padding-bottom: 30px;
        a{
            color: #fff;
            &:hover{
                color: #0099ff;
                text-decoration: underline !important;
            }
        }
    }
}