@import "../../../scss/mixin.scss";
.uploadNftImage{
    padding-top: 20px;
    padding-bottom: 25px;
    label{
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        display: block;
    }
    span{
        font-size: 14px;
        display: block;
        padding-bottom: 20px;
        font-weight: 600;
        color: #9c9c9c;
    }
    i {
        background: rgba(255, 255, 255, 0.15);
        border: 1px dashed #A2A2A2;
        border-radius: 10px;
        display: block;
        width: 350px;
        height: 250px;
        position: relative;
        img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .createnftFile {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
    }
}
.createfrmOuter {
    width: 60%;
    @include respond-to(media-lg) {
        width: 100%;
    }
    .createFrmBx{
        padding-bottom: 25px;
        label{
            display: block;
            font-size: 18px;
            color: #fff;
        }
        span{
            font-size: 14px;
            color: #9c9c9c;
            display: block;
            padding-bottom: 10px;
        }
        .createInp {
            background: rgba(255, 255, 255, 0.15);
            border-radius: 10px;
            border: 0;
            height: 50px;
            color: #fff;
            padding: 0 10px;
            box-shadow: none;
        }
        .selInp {
            background: rgba(255, 255, 255, 0.15) url(../../../assets/images/select-Arrow.png) right 10px center no-repeat;
            border-radius: 10px;
            border: 0;
            height: 50px;
            color: #fff;
            padding: 0 10px;
            box-shadow: none;
            option{
                color: #000;
            }
        }
        
    }
    .createBtn{
        button {
            background: linear-gradient(270deg, #7C0FF6 5.64%, #0A8FFC 87.41%);
            border-radius: 10px;
            width: 250px;
            border: 0;
            height: 65px;
            font-size: 20px;
            font-weight: 600;
        }
    }
}
.createInp2 {
    background: rgba(255, 255, 255, 0.15) !important;
    border-radius: 10px !important;
    border: 0 !important;
    height: 150px;
    color: #fff !important;
    resize: none;
    box-shadow: none !important;
    padding: 10px !important;

}
.propertyList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: #434343 solid 1px;
    .propertyLeft{
        color: #fff;
        strong{
            display: block;
            font-size: 18px;
            padding-bottom: 7px;
        }
        span{
            display: block;
            font-size: 14px;
        }
    }
    .propertyAdd{
        button{
            width: 50px;
            height: 50px;
            border-radius: 10px;
            font-size: 40px;
            color: #fff;
            padding: 0;
            line-height: 50px;
            font-weight: 300;
            background: #333;
            border: 0;
        }
    }
}
.unloackable {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: #434343 solid 1px;
    .unloackTop {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .unlockLeft {
            width: 70%;
            padding-bottom: 10px;
            strong{
                display: block;
                font-size: 18px;
                padding-bottom: 4px;
            }
            span{
                display: block;
                font-size: 14px;
            }
        }
    }
}

select.createInp.wd-100 {
    width: 100%;
    
}
.createfrmOuter .createFrmBx select.createInp option {
    background: #292929;
    color: #fff;
}
// select.createInp::placeholder {
//     color: #000;
//     opacity: 1; /* Firefox */
//   }
  
// select.createInp::-ms-input-placeholder { /* Edge 12-18 */
//     color: #000;
// }
// .createfrmOuter .createFrmBx select.createInp option:not(:first-of-type) {
//     color: green;
// }

.moderatorFormBox {display: flex; align-items: center;}
.createfrmOuter {width: 65%; padding-right: 30px;}
.moderatorImg {width: 35%; padding:0 20px ; text-align: center;}
.editImgMod {padding-bottom: 20px;}
.editImgMod img {width: 120px;}
