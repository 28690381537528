@import "../../../scss/mixin.scss";

.upldBanner {

    i {
        background: rgba(255, 255, 255, 0.15);
        border: 1px dashed #A2A2A2;
        border-radius: 10px;
        display: block;
        width: 578px;
        height: 250px;
        position: relative;

        span {
            display: block;
            font-size: 14px;
            color: #9c9c9c;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding-bottom: 0;
            text-align: center;
            font-style: normal;

            img {
                position: relative;
                left: 0;
                top: 0;
                transform: translate(0, 0);
                display: inline-block;
                padding-bottom: 10px;
            }
        }

    }
}

.uploadNftImage {
    padding-top: 20px;
    padding-bottom: 25px;

    label {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        display: block;
    }

    span {
        font-size: 14px;
        display: block;
        padding-bottom: 20px;
        font-weight: 600;
        color: #9c9c9c;
    }

    i {
        background: rgba(255, 255, 255, 0.15);
        border: 1px dashed #A2A2A2;
        border-radius: 10px;
        display: block;
        width: 350px;
        height: 250px;
        position: relative;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .createnftFile {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
    }
}

.createBtnBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.createBtn{
    button {
        background: linear-gradient(270deg, #7C0FF6 5.64%, #0A8FFC 87.41%);
        border-radius: 10px;
        width: 250px;
        border: 0;
        height: 65px;
        font-size: 20px;
        font-weight: 600;
        margin-right: 15px;
    }
}