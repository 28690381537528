@import "../../../scss/mixin.scss";

.mainBg{
    background: url(../../../assets/images/dashboard-Bg.png) center center no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.dash_container{
    max-width: 1500px;
    padding: 0 25px;
    margin: 0 auto;
}
.DashMidBx{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.dashRightside{
    width:calc(100% - 300px);
    @include respond-to(media-lg) {
        width: calc(100% - 265px);
    }
    .dashRightBx{
        display: flex;
        justify-content: space-between;
        align-items: flex-start; 
        .dashR_L{
            width: calc(100% - 305px);
            @include respond-to(media-lg) {
                width: calc(100% - 265px);
            }
            .graphBx_Outer {
                margin-bottom: 30px;
                display: block;
                justify-content: space-between;
                align-items: stretch;
            }
            .walletbalanceBx {
                display: inline-block;
                width: 48%;
                position: relative;
                color: #fff;
                background: rgba(18, 18, 18, 0.7);
                padding: 45px;
                border-radius:14px;
                padding-left: 80px;
                margin: 15px 1%;
                i{
                    background: rgba(176, 176, 176, 0.1);
                    width: 57px;
                    height: 73px;
                    border-radius: 13px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    img{
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                span{
                    display: block;
                    font-size: 14px;
                    line-height: 17px;
                }
                strong{
                    font-size: 24px;
                    display: block;
                    line-height: 29px;
                    padding: 5px 0;
                }
                small{
                    display: block;
                    font-size: 10px;
                    img{
                        height: 12px;
                    }
                }
            }
            .balanceHd{
                padding: 30px 0;
                span{
                    display: inline-block;
                    background: rgba(0, 0, 0, 0.4);
                    border-radius: 25px;
                    width: 111px;
                    height: 55px;
                    vertical-align: middle;
                    color: #fff;
                    line-height: 55px;
                    text-align: center;
                    img{
                        height:14px;
                    }
                }
                strong{
                    display: inline-block;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    margin: 0 10px;
                    vertical-align: middle;
                }
                small{
                    font-size: 12px;
                    line-height: 14px;
                    display: inline-block;
                    vertical-align: middle;
                    color: #8ec0ff;
                    padding-left: 10px;
                    &:hover{
                        color: #e307af;
                    }
                }
            }
            .chartBx{
                padding-bottom: 30px;
                img{
                    width: 100%;
                }
            }
            .mintokenOuter{
                text-align: center;
                padding: 20px 0 0px 0;
                .mintoken{
                    padding: 20px;
                    background: rgba(176, 176, 176, 0.1);
                    border-radius: 13px;
                    color: #fff;
                    text-align: center;
                    width: 220px;
                    margin: 0 20px;
                    display: inline-block;
                    @include respond-to(media-lg) {
                        width: 150px;
                        margin: 0 10px;
                    }
                    strong{
                        display: block;
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 700;
                        padding-bottom: 15px;
                    }
                    span{
                        background: rgba(49, 53, 88, 0.8);
                        border-radius: 10px;
                        line-height: 23px;
                        text-align: center;
                        display: block;
                        margin-bottom: 15px;
                        font-size: 10px;
                    }
                    a{
                        display: inline-block;
                        width: 105px;
                        line-height: 22px;
                        background: linear-gradient(270deg, #F42BA2 3.56%, #7C0FF6 78.04%);
                        border-radius: 16px;
                        color: #fff;

                    }
                }
            }
            
        }
        .dashR_R{
            width: 285px;
            @include respond-to(media-lg) {
                width: 250px;
            }
            .gamesBx{
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                margin-bottom: 20px;
                color: #fff;
                .gamesInn{
                    width: 48%;
                    background: rgba(18, 18, 18, 0.7);
                    border-radius: 16px;
                    padding: 10px 15px;
                    text-align: center;
                    span{
                        display: block;
                        font-size: 18px;
                    }
                    strong{
                        font-size: 20px;
                    }
                }
            }
            .yourgames{
               background: rgba(18, 18, 18, 0.7);
               padding: 18px 8px;
               border-radius: 16px; 
               .gamesHd{
                    font-size: 18px;
                    color: #fff;
                    font-weight: 400;
                    padding-bottom: 10px;
                    padding-left: 15px;
                }
                .gameList{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: url(../../../assets/images/grouplistBg1.png);
                    background-size: 100% 100%;
                    color: #fff;
                    height: 87px;
                    padding: 18px 25px;
                    &:hover{
                        background: url(../../../assets/images/grouplistBg.png);
                        background-size: 100% 100%;
                    }
                    .gamesLeft{
                        font-size: 10px;
                        strong{ 
                            display: block;
                            padding-bottom: 5px;
                        }
                        span{
                            display: block;
                        }
                    }
                    .gamesRight{
                        font-size: 10px;
                        font-weight: 600;
                    }
                }
                  
            }
        }
    }
}
.transBg{
    background: rgba(0, 0, 0, 0.6);
    margin-bottom: 30px;
    .transHd{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        @include respond-to(media-lg) {
            padding-bottom: 10px;
         }
    }
    .transFltr{
        @include respond-to(media-lg) {
            text-align: right;
        }
    }
    .tableBx{
        @include respond-to(media-lg) {
            overflow: auto;
        }
        table {
            width: 100%;
            @include respond-to(media-lg) {
                min-width: 800px;
            }
            th{
                font-size: 14px;
                color: #fff;
                font-weight: 500;
                padding: 10px 20px;
                line-height: 28px;
                a{
                    color: #fff;
                }
            }
            td{
                border-top: rgba(255, 255, 255, 0.4) dashed 1px;
                padding: 10px 20px;
                color: #fff;
                font-size: 14px;
                line-height: 28px;
                a{
                    color: #94d4ff;
                }
            }
        }
    }
}
.approved{
    width: 93px;
    height: 24px;
    background: #F7FFC8;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: block;
    color: #7E9B08;
    text-align: center;
}
.pending{
    width: 93px;
    height: 24px;
    background: #FFF5C8;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: block;
    color: #E4B304;
    text-align: center;
}
.declined{
    width: 93px;
    height: 24px;
    background: rgba(255, 248, 248, 0.87);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: block;
    color: #ff0000;
    text-align: center;
}
.paginationBx {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ul{
        display: inline-flex;
        li{
            margin-right: 10px;
            a {
                background: transparent !important;
                font-size: 20px;
                color: #fff !important;
                border: 0;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                padding: 0;
                display: inline-block;
            }
            &:first-child, &:last-child{
                a{
                    span{
                        font-size: 20px;
                        line-height: 0;
                    }
                }
            }
        }
        li.active{
            border: #fff solid 1px;
            border-radius: 50%;
        }
        .previous{
            a{
                // background: #2068eb !important;
                font-size: 16px;
                color: #fff;
                height: auto;
                text-align: center;
                line-height: 30px;
                padding: 0 15px;
                // border: #2068eb solid 1px;
                display: inline-block;
                width: auto;
                text-transform: capitalize;
                border-radius: 5px;
            }
        }
        // li.previous.disabled{
        //     a{
        //         opacity: 0.8;
        //     }
        // }
        .next{
            a{
                // background: #2068eb  !important;
                font-size: 16px;
                color: #fff;
                height: auto;
                text-align: center;
                line-height: 30px;
                padding: 0 15px;
                // border: #2068eb solid 1px;
                display: inline-block;
                width: auto;
                text-transform: capitalize;
                border-radius: 5px;
            }
        }
        .active{
            a.page-link {
                border: #fff solid 1px !important;
                border-radius: 50%;
            }
        }
    }
}

.modalOuter{
    .modal-content {
        background: #000;
        box-shadow: #626060 0 0 19px 1px;
        .modal-header{
            border-bottom: #2c2c2c solid 1px;
            .h4{
                color:#fff !important;
            }
            .btn-close {
                opacity: 1;
                background-color: #ffffffab;
            }
        }
        .modal-body{
            label{
                display: block;
                font-size: 18px;
                color: #fff;
                font-weight: 500;
                padding-bottom: 8px;
            }
            .gspFild {
                width: 100%;
                padding-bottom: 20px;
                input{
                    width: 100%;
                    height: 50px;
                    border: #2c2c2c solid 1px;
                    background: transparent;
                    color: #fff;
                    padding: 0 10px;
                    border-radius: 8px;
                }
            }
        }
        .modal-footer{
            border-top:#2c2c2c solid 1px;
            button{
                background: linear-gradient(270deg, #594BD9 5.64%, #5B6EED 87.41%);
                border-radius: 10px;
                width: 150px;
                height: 45px;
                color: #fff;
                font-size: 18px;
                border: 0;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}

.transUserOuter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding: 15px;
    @include respond-to(media-lg) {
        display: block;
     }
}
.srchBx{
    display: inline-block;
    vertical-align: top;
    width: 200px;
    margin-left: 10px;
    @include respond-to(media-lg) {
        width: 150px;
     }
    .mb-3 {
        margin-bottom: 0 !important;
    }
}
.transId{
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    position: relative;
    .mb-3 {
        margin-bottom: 0 !important;
        select{
            background: transparent;
            border: 0;
            border-radius: 0;
            color: #fff;
            box-shadow: none;
            height: 38px;
        }
    }
    svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        color: #fff;
    }
}
.daterange{
    display: inline-block;
    margin-left: 10px;
    width: 150px;
    .mb-3 {
        margin-bottom: 0 !important;
        .form-control{
            box-shadow: none;
        }
    }
}
.form-control{
    box-shadow: none;
}
.downBtn{
    display: inline-block;
    margin-left: 10px;
    button{
        background: transparent !important;
        padding: 0;
        border: 0;
        height: 34px;
        font-size: 24px;
    }
}
.dateInpBx{
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 300px;
    margin-left: 10px;
    @include respond-to(media-lg) {
        width: 130px;
     }
    .dateInp{
        width: 100%;
        height: 40px;
        background: #fff;
        border-radius: 5px;
        border: 0;
        padding: 0 10px;
    }
    svg{
        position: absolute;
        right: 10px;
        top: 13px;
    }
}

.refreshBtn{
    display: inline-block;
    margin-left: 10px;
    button{
        background: transparent !important;
        border: #ccc solid 1px !important;
        height: 40px;
        font-size: 23px;
        padding: 0;
        width: 40px;
        line-height: 35px;
    }
}
.tableBx{
    overflow: auto;
}
.fltrBx {
    display: inline-block;
    margin-left: 10px;
    button {
        background: #5253d6;
        border: #5253d6 solid 1px;
    }
}

.propertyList.optionList {
    display: block;
    .inner {
        display: flex;
        input.formInput {
            width: calc(100% - 145px);
            background: rgba(255, 255, 255, 0.15);
            border-radius: 10px;
            border: 0;
            height: 50px;
            color: #fff;
            padding: 0 10px;
            box-shadow: none;
        }
        > span {
            margin-left: 10px;
        }
        strong {
            width: 25px;
            color: #fff;
            line-height: 50px;
        }
    }
}
.nftsaveOuter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .createBtn{
        button {
            background: linear-gradient(270deg, #7C0FF6 5.64%, #0A8FFC 87.41%);
            border-radius: 10px;
            width: 250px;
            border: 0;
            height: 65px;
            font-size: 20px;
            font-weight: 600;
            margin-right: 15px;
        }
    }
}
.levecount{
    table{
        tr{
            td{
                span{
                    display: block;
                    line-height: 30px;
                }
            }
        }
    }
}

ul.quesanslist {
    padding: 10px 20px 20px 20px !important;
    li {
        display: inline-block;
        width: 33%;
        color: #fff;
        line-height: 30px;
    }
}

// preview page css
.previewPopup {width: 920px; min-width:900px;}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
.mainBox {width: 860px; margin: auto;}
.topTabs {display: flex; font-family: 'Roboto', sans-serif; margin: -5px; padding:0 0 20px 0; list-style: none;}
.topTabs li {padding: 5px;}
.topTabs li a {display: flex; align-items: center; padding: 0 20px; border-radius: 4px; background: #666; color: #fff; height: 36px; text-decoration: none;}
.topTabs li a:hover,  .topTabs li.active a {background: #191970;}

.tablet {width: 860px; height: 600px; font-family: 'Roboto', sans-serif; border-radius: 30px; position: relative; margin: auto; padding: 30px; box-shadow: 10px 10px 10px rgba(0,0,0,.2); background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(34,34,34,1) 50%,rgba(51,51,51,1) 100%);}
.tabletInner {width: 800px; height: 540px; background: #fff url(../../../assets/images/background.png); background-size: cover !important;}
.infoBox {width:760px; margin: auto; padding: 20px 0;}
.topBox {display: flex; justify-content: space-between; align-items: center; padding-bottom: 20px;}
.topLeft {display: flex; margin: 0; padding: 0; list-style: none;}
.topLeft li {color: #fff; font-size: 15px; font-weight: 500; border-left: 1px solid #fff; padding: 0 20px;}
.topLeft li:first-child {border-left: none; padding-left: 0;}
.topLeft li span {font-weight: 400; display: block; padding-top: 2px;}
.topRight {display: flex; align-items: center; margin: 0; padding: 0; list-style: none;}
.topRight li {color: #fff; font-size: 15px; padding-left: 20px;}
.detBox {margin-bottom: 20px;}
.imgBox {margin-bottom: 10px; background: #999; border-radius: 6px; height: 252px; display: flex; align-items: center; justify-content: center;}
.imgBox img {border-radius: 6px; max-width: 100%; max-height: 100%;}
.qBox {background: #646E82; border-radius: 6px; padding: 15px; text-align: center; display: flex; align-items: center; justify-content: center; min-height:80px; color: #fff; font-size: 15px;}
a.seeAnswer {background: #fff; height: 46px; border-radius: 6px; font-size: 15px; font-weight: 500; color: #000; display: flex; align-items: center; justify-content: center; color: #000;}

#bot {display: flex; flex-direction: column-reverse;}
#bot .imgBox {margin: 10px 0 0 0;}        
#left {display: flex; justify-content: space-between; margin-bottom: 20px;}
#left .imgBox {width: 49%; margin: 0;}
#left .qBox {width: 49%;}        
#right {display: flex; justify-content: space-between; flex-direction: row-reverse; margin-bottom: 20px;}
#right .imgBox {width: 49%; margin: 0;}
#right .qBox {width: 49%;}

.nxtPrevBtn {text-align: right;
    button {margin-left: 5px; background: linear-gradient(270deg, #7C0FF6 5.64%, #0A8FFC 87.41%); color: #fff; border: none; border-radius: 6px;}
}

.tabletInner.prevFileOuter {
    position: relative;
}

.tabletInner.prevFileOuter .imgBox {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100% !important;
}
.tabletInner.prevFileOuter .imgBox img {
    width: 100%;
    height: 100%;
}

.topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    position: absolute;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
}

a.seeAnswer {
    background: linear-gradient(270deg, #7C0FF6 5.64%, #0A8FFC 87.41%);
    height: 46px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    z-index: 99;
    padding: 0 15px;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
}

#left .qBox, #right .qBox, #bot .qBox{
    width: 80%;
    position: absolute;
    right: 10%;
    bottom: 83px;
    border-radius: 10px;
    /* bottom: 0; */
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    padding: 10px !important;
    min-height: 0;
}
#top .qBox{
    width: 80%;
    position: absolute;
    right: 10%;
    top: 80px;
    border-radius: 10px;
    /* bottom: 0; */
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
}


#left .imgBox{
    width: 50%;
}
#right .imgBox{
    left: auto;
    width: 50%;
}

.optBox {
    width: 50%;
    position: absolute;
    bottom: 0;
    border-radius: 10px;
    top: 0;
    font-size: 24px;
    background:#999;
    display: none;
}
#left .optBox {
    right: 0;
    display: block;
}
#right .optBox {
    right: auto;
    display: block;
    left: 0;
}
.optBox ul {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 20px !important;
    width: 100%;
}
.optBox ul li {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    color: #fff;
    font-size: 18px;
    display: block;
    background-image: linear-gradient(to right, #000000 0%, #464646  51%, #000000  100%);
}

.nftInner {display: flex; flex-wrap: wrap;}
.nftTable {width: 40%; padding-left: 20px;
    @include respond-to(media-lg) {
        width:100%; padding: 20px 0 0 0;
     }
}
         
