@import "../../../scss/mixin.scss";

.partnersBx{
    padding: 20px 100px 50px 100px;
    .partnersHd{
        h2{
            font-weight: 400;
            font-size: 60px;
            line-height: 76px;
            text-align: center;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            padding-bottom: 80px;
            color: #fff;
        }
    }
    .partnersList{
        ul{
            li{
                display: inline-block;
                margin-right: 30px;
                margin-bottom: 110px;
            }
        }
    }
}