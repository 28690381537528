@import "../../../scss/mixin.scss";
.collDetBanner{
    margin-bottom: 15px;
    padding-top: 15px;
    img{
        width: 100%;
    }
}
.collectionDetail{
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    background: rgba(49, 47, 49, 0.6);
    border: 1px solid #545454;
    border-radius: 16px;
    margin-bottom: 15px;
    .collBx{
        color: #fff;
        span{
            font-size: 15px;
            line-height: 34px;
            display: block;
        }
        strong{
            font-size: 20px;
            line-height: 34px;
        }
    }
}