@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Montserrat:wght@300;400;500;600;700&display=swap');
body {
  font-family: 'Montserrat', sans-serif !important;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Audiowide', cursive;
}
a{
  text-decoration: none !important;
}
ul{
  margin: 0 !important;
  padding: 0 !important;
  list-style: none;
}
img{
  max-width:100%;
}
.mb-5{
  margin-bottom: 50px;
}
.container{
  max-width: 1440px !important;
  margin: 0 auto;
  padding: 0 15px;
}
